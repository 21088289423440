import './definition.scss';
import { Tooltip } from 'reactstrap';
import React, { useState, useRef, FC, ReactNode } from 'react';

import glossaryTerms from '../../data/glossaryTerms';

export type DefinitionProps = {
  /** The term that should be displayed as a highlight with tooltip in place of a glossary item's default term */
  displayAs?: ReactNode;
  /** The default glossary term to be displayed as a highlight with tooltip */
  term?: string;
  // An HTML-valid id string
  id: string;
};

const Definition: FC<DefinitionProps> = ({ displayAs = '', term = '', id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const definitionRef = useRef(null);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const snakeCase = string => {
    return string
      .replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  };

  const getDefinition = () => {
    let thisID = id || snakeCase(term);
    let thisDefinition;
    glossaryTerms.forEach(glossaryItem => {
      if (glossaryItem.id.trim() === thisID.trim()) {
        //trim necessary for equivalency...whitespace being inserted somehow. Also caused array.find() to fail
        thisDefinition = glossaryItem.definition;
      }
    });
    return thisDefinition || 'definition not found';
  };

  //https://reactstrap.github.io/components/tooltips/
  return (
    <>
      <span className="definition" ref={definitionRef}>
        {displayAs || term}
      </span>
      <Tooltip
        isOpen={tooltipOpen}
        target={definitionRef}
        toggle={toggle}
        trigger="hover"
        delay={{ show: 0, hide: 50 }}
        className="definition__tooltip"
        hideArrow={false}
        fade={true}
        //boundariesElement='scrollParent'
      >
        <span
          className="definition__tooltip_content"
          // dangerouslySetInnerHTML={{ __html: getDefinition() }}
        >
          {getDefinition()}
        </span>
      </Tooltip>
    </>
  );
};

export default Definition;
