/**
 * Some glossary entries require alternate 'displayAs' terms for the Glossary section
 * that differ from their default term names
 */

import React, { ReactNode } from 'react';
import 'twin.macro';
import tw from 'twin.macro';

export type GlossaryTermProps = {
  /** an HTML-valid id string */
  id: string;
  /** The main glossary term */
  term?: string;
  /** An alternate term(s) to display using the same definition as an entry's main term - this supercedes 'term' in the Glossary section */
  displayTerm?: string;
  /** The definition for a given term/displayTerm */
  definition?: ReactNode;
};

const BlueBullet = tw.li`marker:(text-brand-blue-light)`;

const glossaryTerms: GlossaryTermProps[] = [
  {
    id: 'biopsy',
    term: 'Biopsy',
    definition:
      'A medical test in which body tissue or cells are studied to look for a disease and figure out how dangerous that disease might be. A biopsy usually involves a minor operation to get a sample of body tissue and cells to be studied.',
  },
  {
    id: 'bladder_cancer',
    term: 'Bladder cancer',
    definition:
      'A cancer that happens in the lining of the bladder, which is in the lower part of the urinary tract.',
  },
  {
    id: 'catheter',
    term: 'Catheter',
    definition: 'A thin, flexible tube.',
  },
  {
    id: 'chronic_kidney_disease',
    term: 'Chronic kidney disease',
    definition:
      'A disease in which your kidney or kidneys are not working as well as they should be. Many people suffer from chronic kidney disease. If it gets bad enough, a machine might be needed to filter the blood (dialysis), or the person might need a kidney transplant.',
  },
  {
    id: 'endoscope',
    term: 'Endoscope',
    definition:
      'A tool used to look inside the body. Urologists use a certain type of endoscope, called a ureteroscope, to see where tumors are located. It also lets them see what they’re doing during endoscopic procedures. ',
  },
  {
    id: 'endoscopic_management',
    term: 'Endoscopic management',
    displayTerm:
      'Endoscopic management/endoscopic resection/endoscopic surgery/endoscopic treatment',
    definition:
      'Minor operations that a urologist does to remove UTUC tumors. These operations can be done using a laser, forceps, or another surgical tool. They’re always done using an endoscope, which lets the urologist see inside the body during the operation.',
  },
  {
    id: 'forceps',
    term: 'Forceps',
    definition:
      'A tool used to remove tumors or tumor tissue during an endoscopic procedure. Ureteral forceps have tiny pincers (similar to tweezers) at the end of a long, skinny tube.',
  },
  {
    id: 'grade',
    term: 'Grade',
    definition:
      'The term used to describe how likely cancer cells are to grow and spread. Low-grade UTUC usually grows slowly and is less likely to spread than high-grade UTUC.',
  },
  {
    id: 'high_grade',
    term: 'High grade',
    definition:
      'The term used to describe cancer cells that are more aggressive and are more likely to spread to other parts of the organ or other parts of the body.',
  },
  {
    id: 'high_risk',
    term: 'High risk',
    definition:
      'The term used to describe cancer that is more dangerous. The risk of UTUC usually depends on grade, stage, tumor—size, location, and number of tumors—and the overall health of the person.',
  },
  {
    id: 'kidney_cancer',
    term: 'Kidney cancer',
    definition:
      'Kidney cancer is cancer that begins in the kidneys. In adults, the most common type of kidney cancer is renal cell carcinoma.',
  },
  {
    id: 'low_grade',
    term: 'Low grade',
    definition:
      'The term used to describe cancer cells that are less aggressive and are less likely to spread to other parts of the organ or other parts of the body.',
  },
  {
    id: 'low_risk',
    term: 'Low risk',
    definition:
      'The term used to describe cancer that is less dangerous. The risk of UTUC depends on grade, stage, tumor—size, location, and number of tumors—and the overall health of the person.',
  },
  {
    id: 'lynch_syndrome',
    term: 'Lynch syndrome',
    definition:
      'A condition that is passed down through families and increases a person’s risk for several types of cancer.',
  },
  {
    id: 'nephrostomy_tube',
    term: 'Nephrostomy tube',
    definition:
      'An external tube placed through your skin into your kidney, designed to help drain urine from the kidney and ureter.',
  },
  {
    id: 'nephroureterectomy',
    term: 'Nephroureterectomy (NU)',
    definition:
      'The medical name for the surgery that is done to remove a kidney, ureter, and a small part of the bladder. This could also be called a radical nephroureterectomy, or RNU.',
  },
  {
    id: 'radical_nephroureterectomy',
    term: 'Radical nephroureterectomy (RNU)',
    definition:
      'The medical name for the surgery that is done to remove a kidney, ureter, and a small part of the bladder. This could also be called a nephroureterectomy, or NU.',
  },
  {
    id: 'recurrence',
    term: 'Recurrence',
    definition:
      'Cancer that has come back following a period when the cancer could not be detected. Also called recurrent cancer.',
  },
  {
    id: 'renal_cell_cancer',
    term: 'Renal cell cancer/renal cell carcinoma (RCC)',
    definition:
      'A type of cancer that happens in the kidneys. Some people get this type of cancer confused with UTUC, so be sure to ask your urologist which one you have.',
  },
  {
    id: 'risk',
    term: 'Risk',
    definition: (
      <>
        <p>
          The term used to describe how dangerous the cancer is. The risk of
          UTUC usually depends on grade, stage, tumor—size, location, and number
          of tumors—and the overall health of the person.
        </p>
        <p>
          Urologists usually think about UTUC as low risk or high risk. Low-risk
          UTUC is not considered as dangerous as high-risk UTUC.
        </p>
      </>
    ),
  },
  {
    id: 'stage',
    term: 'Stage',
    definition: (
      <>
        <p>
          This term is used to describe how deep the cancer has grown into the
          tissue of the organ. The stage of your cancer could be anywhere from 0
          to 4. UTUC that is stage 0 is right on the surface of your urothelium.
          UTUC that is stage 4 has grown deep into your kidney or ureter and has
          also spread to another part of your body.
        </p>
        <p>
          It can be hard to know what stage UTUC is. Your urologist might not
          always be able to tell.
        </p>
      </>
    ),
  },
  {
    id: 'tumor',
    term: 'Tumor',
    definition:
      'An abnormal lump or mass of tissue. Tumors can be benign (not cancerous) or malignant (cancerous).',
  },
  {
    id: 'upper_tract_urothelial_cancer',
    term: 'Upper tract urothelial cancer/upper tract urothelial carcinoma (UTUC)',
    definition: (
      <>
        <p>
          A cancer that happens in the lining of the upper part of the urinary
          tract, in the kidneys, or ureters. Your urologist might call this type
          of cancer by other names, such as
        </p>
        <ul tw="my-4">
          <BlueBullet>Cancer of the upper urinary tract</BlueBullet>
          <BlueBullet>Upper tract urothelial carcinoma</BlueBullet>
          <BlueBullet>Renal pelvis cancer</BlueBullet>
          <BlueBullet>Ureteral cancer</BlueBullet>
          <BlueBullet>Urinary tract cancer</BlueBullet>
          <BlueBullet>Upper tract cancer</BlueBullet>
          <BlueBullet>Cancer in the lining of the kidney</BlueBullet>
        </ul>
      </>
    ),
  },
  {
    id: 'upper_urinary_tract',
    term: 'Upper urinary tract',
    definition:
      'The top half of the urinary tract. This is the name used to refer to the kidneys and ureters.',
  },
  {
    id: 'upper_urinary_tract_cancer',
    term: 'Upper urinary tract cancer',
    definition: 'Another name for UTUC.',
  },
  {
    id: 'ureter',
    term: 'Ureter',
    definition:
      'The tube-shaped part of the urinary tract that moves urine from the kidneys to the bladder. The human body has 2 ureters, each connecting a kidney to the bladder.',
  },
  {
    id: 'ureteroscope',
    term: 'Ureteroscope',
    definition:
      'A tool used to look inside the body. A ureteroscope is a type of endoscope, and urologists use it to see where tumors are located. It also lets them see what they’re doing during endoscopic procedures.',
  },
  {
    id: 'urethra',
    term: 'Urethra',
    definition:
      'The bottom part of the urinary tract. When you urinate, the urine leaves the body through the urethra.',
  },
  {
    id: 'urinary_tract',
    term: 'Urinary tract',
    definition:
      'The name for the body parts that work together to collect, store, and remove urine from the body. Those body parts include the kidneys, the ureters, the bladder, the urethra, and many muscles and nerves.',
  },
  {
    id: 'urologist',
    term: 'Urologist',
    definition:
      'A doctor who specializes in treating conditions of the urinary tract.',
  },
  {
    id: 'urothelial_cancer',
    term: 'Urothelial cancer/urothelial carcinoma',
    definition:
      'A cancer that happens in the urinary tract. It’s called urothelial because it usually happens in the urothelium, which is the tissue that lines the inside of the urinary tract. Your urologist might simply call it urinary tract cancer.',
  },
  {
    id: 'urothelium',
    term: 'Urothelium',
    definition:
      'The tissue that lines the inside of the urinary tract. Most urothelial cancer happens in the urothelium.',
  },
];

export default glossaryTerms;
